<template>
  <div class="container">

    <LoadingEmpresaContabilidade v-if="!usuario.Nome" :speed="1" :width="400" :height="160" style="margin-top: 25%;"
                                 class="text-center"></LoadingEmpresaContabilidade>

    <div v-else>
      <div class="row btnBackPage">
        <div class="d-flex align-items-center py-2 gap-sm-4">
          <BtnBackPage/>
        </div>
        <div class="col-sm-12">
          <p class="subtitulo">
            Passo 01 de 02
          </p>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-sm-5">
          <span class="titulo">
            Para começar, precisamos dos dados da sua empresa.
          </span>
        </div>
      </div>
      <form id="app" class="form">

        <div class="row" style="margin-bottom: 2%">

          <div class="col-sm-4">

            <div class="form-group  mt-2">
              <label for="CNPJ" class="label_form"> CNPJ da empresa contábil </label>

              <input type="text" v-mask="['##.###.###/####-##']"
                     :class=" { ' form-control form-easyanest is-invalid': v$.CNPJ.$error, 'form-control form-easyanest ': !v$.CNPJ.$error } "
                     v-model.trim="CNPJ"
                     v-on:blur.passive="consultaCNPJAPI"
                     aria-describedby="cnpj-help-block"
                     placeholder="Digite seu CNPJ"
                     id="CNPJ" name="CNPJ"/> <span class="is-invalid-text" v-if="v$.CNPJ.$error">
                {{ v$.CNPJ.$errors[0].$message }}
              </span> <span class="is-valid" v-else>&nbsp;</span>


            </div>
            <div class="form-group  mt-2">
              <div class="teste">
                <label for="razao_social" class="label_form">Nome da empresa contábil</label>
                <input type="text" v-model="razao_social"
                       :class=" { ' form-control form-easyanest is-invalid': v$.razao_social.$error, 'form-control form-easyanest ': !v$.razao_social.$error }"
                       placeholder="Digite o nome da empresa"
                       id="razao_social" name="razao_social">
                <span class="is-invalid-text" v-if="v$.razao_social.$error">
                  {{ v$.razao_social.$errors[0].$message }}
                </span> <span class="help_text" v-else>&nbsp;</span>
              </div>
            </div>

            <div class="form-group  mt-2">
              <label for="telefone" class="label_form"> Telefone da empresa </label>
              <input type="text" v-mask="'(##) #####-####'"
                     :class=" { ' form-control form-easyanest is-invalid': v$.telefone.$error, 'form-control form-easyanest ': !v$.telefone.$error }"
                     v-model="telefone"
                     placeholder="Digite o telefone da empresa" id="telefone" name="telefone"/>

              <span class="is-invalid-text" v-if="v$.telefone.$error">
                {{ v$.telefone.$errors[0].$message }}
              </span> <span class="help_text" v-else>&nbsp;</span>
            </div>
            <hr style="border: 1px solid #EEEEEE;">
            <div class="form-group  mt-2">
              <label for="contato" class="label_form"> Nome do contador responsável </label>
              <input type="text" v-model="contato"
                     :class=" { ' form-control form-easyanest is-invalid': v$.contato.$error, 'form-control form-easyanest ': !v$.contato.$error }"
                     placeholder="Digite o seu nome completo" id="contato" name="contato">
              <span class="is-invalid-text" v-if="v$.contato.$error">
                {{ v$.contato.$errors[0].$message }}
              </span> <span class="help_text" v-else>&nbsp;</span>
            </div>
            <div class="form-group  mt-2">

              <label for="telefone_contato" class="label_form"> Telefone do contador responsável </label>
              <input type="text" v-mask="['(##) ####-####', '(##) #####-####']" v-model="telefone_contato"
                     :class=" { ' form-control form-easyanest is-invalid': v$.telefone_contato.$error, 'form-control form-easyanest ': !v$.telefone_contato.$error }"

                     placeholder="Digite o seu telefone para contato" id="telefone_contato" name="telefone_contato"/>
              <span class="is-invalid-text" v-if="v$.telefone_contato.$error">
                {{ v$.telefone_contato.$errors[0].$message }}
              </span> <span class="help_text" v-else>&nbsp;</span>
            </div>

          </div>
        </div>
        <hr style="border: 1px solid #EEEEEE;">
        <button type="button" class="btn botao_criar_conta" @click="avancarTela">
          Avançar
        </button>
      </form>
    </div>

  </div>
</template>
<style>

body {
  background-color: #FFFFFF !important;
}

@media (max-width: 575px) {
  .subtitulo {

  }

  .btnBackPage {
    padding-top: 30px;
  }
}

.frase {
  left: 124px;
  top: 592px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 148%;
  /* identical to box height, or 24px */
  color: #7E7F80;
}




.help_text {
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  /* identical to box height, or 133% */
  text-align: center;
  color: #C0C0C0;
}


.btnBackPage {
  padding-top: 30px;
}


</style>
<script>

import appConfig from "../../../../app.config";
//import axios from 'axios';
import LoadingEmpresaContabilidade from "@/components/loading/LoadingEmpresaContabilidade.vue";
import consultaAPICNPJ from "consultar-cnpj";
import BtnBackPage from "@/components/BtnBackPage.vue";
import useValidate from "@vuelidate/core";
import {required, helpers, minLength} from '@vuelidate/validators';
import {mask} from 'vue-the-mask';
import router from "@/router";
// import Grazi from "@/components/Grazi.vue";

export default {
  directives: {mask},
  components: {
    // Grazi,
    BtnBackPage,

    LoadingEmpresaContabilidade
  },
  page: {
    title: "Compartilhamento",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {

      usuario: {
        Nome: null,
      },
      CNPJ: '',
      razao_social: '',
      telefone: '',
      contato: '',
      telefone_contato: '',
      alerta: {
        hidden: false
      }
    }
  },
  created() {
    const hash = localStorage.getItem('hash');
    const usuario = JSON.parse(localStorage.getItem('info_usuario'));
    this.usuario.Nome = usuario.Nome;
    if (!hash) {
      this.$router.push('./');
    }
  },
  methods: {
    async consultaCNPJAPI(i) {

      try {
        this.v$.$validate();
        const cnpj = i.target.value;
        const clean = cnpj.replace(/[^0-9,]*/g, '').replace(',', '.');

        if (clean.length >= 14) {
          const empresa = await consultaAPICNPJ(clean);


          if (!empresa.status) {
            this.razao_social = empresa.razao_social;
            this.telefone = empresa.estabelecimento.ddd1 + ' ' + empresa.estabelecimento.telefone1;
          } else {
            this.v$.CNPJ.$error = true;
            this.v$.CNPJ.$errors[0].$message = empresa.detalhes
          }

          localStorage.setItem('empresa', JSON.stringify(empresa));

        } else {
          this.v$.CNPJ.$error = true;
          this.v$.CNPJ.$errors[0].$message = 'CNPJ inválido'

        }

      } catch (e) {
        console.log(e);
      }
    },
    avancarTela() {
      try {

        //Valida os dados
        //console.log(this.v$);
        this.v$.$validate();
        if (!this.v$.$error) {
          console.log('Sem erros no formulario');
          let data_form = {};
          data_form.CNPJ = this.CNPJ;
          data_form.razao_social = this.razao_social;
          data_form.telefone = this.telefone;
          data_form.contato = this.contato;
          data_form.telefone_contato = this.telefone_contato;
          localStorage.setItem('form_cadastro', JSON.stringify(data_form));

          return router.push({path: '/contabilidade/novaconta/2/'});
        } else {
          console.log('Formulario com erros');
        }


      } catch (e) {
        console.log(e);
      }
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
  },
  validations() {

    //const MessageForm = (value) =>  !helpers.req(value);
    //const MessageFormMin = (value) =>  !helpers.req(value);

    return {
      CNPJ: {
        required: helpers.withMessage('Campo obrigatório', required),
        min: helpers.withMessage('Favor inserir os 14 digitos', minLength(14)),
      },
      razao_social: {
        required: helpers.withMessage('Favor preencher com o nome da empresa responsável', required)
      },
      telefone: {
        required: helpers.withMessage('Campo obrigatório', required)
      },
      contato: {
        required: helpers.withMessage('Campo obrigatório', required)
      },
      telefone_contato: {
        required: helpers.withMessage('Campo obrigatório', required)
      },
    }
  },
  validationConfig: {
    $lazy: true,
  },
  setup() {
    return {v$: useValidate()}
  },
}
</script>


